import PostLayout from 'components/PostLayout'
import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { SEO } from 'components/seo'
import { SEO2 } from 'components/seo2'
import Layout from 'components/Layout'
import { Posts, PostToggle } from 'components/Blog'
import Pagination from 'components/Pagination'
import { NewsletterForm } from 'components/NewsletterForm'
import SupportCTA from 'components/SupportCTA'
import ArrayCTA from 'components/ArrayCTA'
import { capitalize } from 'instantsearch.js/es/lib/utils'

const EventsCategory = ({
    data: {
        allEventsRecent: { edges: allEventsRecent },
        allEventsPopular: { edges: allEventsPopular },
    },
    pageContext: { category, numPages, currentPage, base },
}) => {
    const [allEventsFilter, setAllEventsFilter] = useState<'latest' | 'popular'>('latest')
    const handleToggleChange = (checked: boolean) => {
        const eventsFilter = checked ? 'popular' : 'latest'
        localStorage.setItem('eventsFilter', eventsFilter)
        setAllEventsFilter(eventsFilter)
    }

    useEffect(() => {
        setAllEventsFilter(localStorage?.getItem('eventsFilter') || 'latest')
    }, [])

    const events = allEventsFilter === 'popular' ? allEventsPopular : allEventsRecent

    return (
        <Layout>
            <SEO title={`${category}`} />

            <PostLayout
                article={false}
                title="Event"
                // menu={event}
                hideSidebar
                hideSurvey
            >
                <Posts
                    titleBorder
                    title={category}
                    posts={events?.slice(0, 4)}
                    action={<PostToggle checked={allEventsFilter === 'popular'} onChange={handleToggleChange} />}
                />
                <NewsletterForm />
                <Posts posts={events?.slice(4, 12)} />
                {events?.length > 12 && (
                    <>
                        <ArrayCTA />
                        <Posts events={events?.slice(12)} />
                    </>
                )}
                <Pagination currentPage={currentPage} numPages={numPages} base={base} />
            </PostLayout>
        </Layout>
    )
}

export default EventsCategory

export const pageQuery = graphql`
    query ($skip: Int!, $limit: Int!, $category: String) {
        allEventsRecent: allMdx(
            limit: $limit
            skip: $skip
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { category: { eq: $category } }, fields: { slug: { regex: "/^/webinars-events/" } } }
        ) {
            totalCount
            edges {
                node {
                    ...BlogFragment
                }
            }
        }
        allEventsPopular: allMdx(
            limit: $limit
            skip: $skip
            sort: { order: DESC, fields: [fields___pageViews] }
            filter: { frontmatter: { category: { eq: $category } }, fields: { slug: { regex: "/^/webinars-events/" } } }
        ) {
            totalCount
            edges {
                node {
                    ...BlogFragment
                }
            }
        }
    }
`
